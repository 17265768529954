import * as React from 'react';

const SvgRepair = ({title, titleId, ...props}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: 'evenodd',
			clipRule: 'evenodd',
			strokeLinejoin: 'round',
			strokeMiterlimit: 2,
		}}
		viewBox="0 0 681 681"
		width="1em"
		height="1em"
		fill="currentColor"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path d="M340.5 155.086c102.333 0 185.414 83.081 185.414 185.414q0 3.836-.155 7.635l109.583-63.268C609.227 145.835 487.068 40.5 340.5 40.5V.149L171.375 97.793 340.5 195.437zM600.311 490.502l34.942 20.174V315.387l-169.125 97.644 34.948 20.177c-32.079 55.403-92.006 92.706-160.576 92.706-36.494 0-70.54-10.566-99.242-28.803v126.558c31.069 10.902 64.469 16.831 99.242 16.831 110.947 0 207.908-60.357 259.811-149.998M179.924 433.208c-15.794-27.279-24.838-58.946-24.838-92.708 0-71.292 40.323-133.24 99.387-164.262l-109.484-63.211C81.029 168.062 40.5 249.585 40.5 340.5c0 54.628 14.633 105.865 40.189 150.002l-34.942 20.174 169.125 97.644V413.031z" />
		<path d="M421.529 259.471c44.721 44.721 44.722 117.337 0 162.058-44.721 44.722-117.337 44.722-162.058 0-44.722-44.721-44.722-117.337 0-162.058 44.721-44.722 117.337-44.721 162.058 0m-119.215 23.723h-.003l-19.103 19.096v.002l17.922 17.928c11.194 11.199 11.19 29.351-.008 40.545l-17.928 17.921v.003l19.096 19.103h.002l17.929-17.922c11.198-11.194 29.35-11.19 40.544.008l17.921 17.928h.003l19.103-19.096v-.002l-17.922-17.929c-11.194-11.198-11.19-29.35.008-40.544l17.928-17.921v-.003l-19.096-19.103h-.002L360.78 301.13c-11.199 11.194-29.351 11.19-40.545-.008z" />
	</svg>
);
export default SvgRepair;
